<template>
    <div class="container">
        <h2>BARRAS</h2>
        <v-alert
            dense
            :type="success?'success':'error'"
            >
            <strong>{{mensaje}}</strong>
        </v-alert>
        <v-divider></v-divider>
        <label for="">Codigo Interno</label>
        <v-text-field type="text" ref="primero" v-model="codigo_interno" @keypress.enter="$refs.otro.focus();"></v-text-field>
        <label for="">RFID</label>
        <v-text-field type="text" ref="otro" v-model="codigo_rfid" @keypress.enter="guardar()"></v-text-field>

        
        <!-- <input type="text" v-model="codigo_rfid"/> -->

    </div>
</template>
<script>
//import {mapState} from 'vuex';
export default {
    data: () => ({
        index_pistoleo:0,
        codigo_rfid:'',
        codigo_interno:'',
        success:true,
        mensaje:'',
        base_url:'https://intranet.avanze.cl/',
        headers:{
          'headers':{
          'Content-Type':'application/json;charset=utf-8',
            //'Authorization':`${state.token}`
          }
        }
  }),
  mounted(){
      this.$refs.primero.focus();
  },
  methods:{
      async guardar(){
          let body = {
              rfid_code:this.codigo_rfid.replace('ñ',''),
              serial_code:this.codigo_interno,
              original_rfid_code:this.codigo_rfid
          }
          await this.axios.post(`${this.base_url}tag`,body,this.headers).then((res)=>{
              console.log('registrado',res.data);
              this.mensaje = res.data.mensaje;
              this.success = res.data.success;
              this.codigo_interno = '';
              this.codigo_rfid = '';
              this.$refs.primero.focus();
          }).catch((e)=>{
            console.log('error',e);
            this.mensaje = 'Problemas con el Servidor';
            this.success = false;
            this.codigo_interno = '';
            this.codigo_rfid = '';
            this.$refs.primero.focus();

          })
          console.log('registro',body);
          //this.$refs.primero.focus();
      },

  },
  computed:{
      //...mapState(['base_url','headers'])
  }
}
</script>